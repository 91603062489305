<template>
  <!-- 顶部导航 -->
  <div class="header" id="header">
    <div class="navigation content-box gn-flex-between">
      <div class="nav-left">
        <div class="left-logo" @click="goHome">
          <img src="@/assets/logo11111.png" alt=""/>
          <div>广州市农药肥料应用协会</div>
        </div>
      </div>
      <div class="nav-right gn-flex-center">
        <span class="icon-name">菜单</span>
        <a-icon class="icon" type="menu" v-if="!isLargeScreen" @click="showDrawer" />
        <ul class="nav-list gn-flex-center" v-else >
          <li v-for="(item, i) in nav" :key="i" class="nav-item gn-flex-center" :class="{ current: checkRouter(item) }">
            <router-link :to="item.href">{{ item.title }}</router-link>
            <ul
              class="dropdown"
              v-if="item.children.length > 0"
            >
              <li v-for="(jt, j) in item.children" :key="j">
                <router-link :to="jt.href">{{ jt.title }}</router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
    <!-- 菜单抽屉 -->
    <a-drawer
      placement="right"
      :closable="false"
      :visible="visible"
      @close="onClose"
      :width="'60vw'"
      :headerStyle="{ height: '50px' }"
    >
      <div slot="title" style="display: flex;">
        <a href="/">
          <img src="@/assets/logo11111.png" alt=""/>
        </a>
        <div class="title-text">广州市农药肥料应用协会</div>
      </div>
      <a-menu
        style="width: 100%"
        :default-selected-keys="defaultSelected"
        :open-keys.sync="openKeys"
        mode="inline"
      >
        <!-- 手机 -->
        <!-- 一级菜单自定义 -->
        <a-menu-item  @click="toMenuPage(item)" v-for="(item) in nav" :key="item.href">
          <router-link :to="item.href" style="display:block">
            <span>{{ item.title }}</span>
          </router-link>
        </a-menu-item>
      </a-menu>
    </a-drawer>
  </div>
</template>

<script>
import { backTop } from '@/utils/backTop'
import { menuList, getMenuPath } from '@/utils/website'
import { mapGetters } from 'vuex'
import { addView } from '@/api/officialWebsite'
import { getBannerList } from '@/api/admin'

export default {
  name: 'Header',
  components: {},
  computed: {
    // 当前是不是手机端
    isLargeScreen () {
      return this.$store.state.IS_LARGE_SCREEN
    },
    ...mapGetters(['WEBSITE_MENU', 'FLATTEN_MENU', 'WEBSITE_BANNER'])

  },
  watch: {
    $route: {
      async handler (to, from) {
        this.onClose()
        // backTop()
        if (!this.FLATTEN_MENU.length) {
          console.log('header获取菜单')
          await menuList()
        }
        // 路径home被重定向到""
        const path = getMenuPath(this.$route.path) || '/home'
        const Item = this.FLATTEN_MENU.find(item => item.href === path)
        // 设置meta用于seo
        if (Item) {
          this.meta_setting = {
            title: Item.seo_title || Item.title,
            keywords: Item.seo_key_words || '',
            description: Item.seo_desc || ''
          }
          // 增加浏览量
          addView({ code: Item.key || Item._p_key }).then(res => { }).catch(err => console.error(err))
        }
      },
      immediate: true // 立即执行
    }
  },
  data () {
    return {
      nav: [
        {
          children: [],
          href: '/home',
          key: '0001',
          title: '首页'
        },
        {
          children: [],
          href: '/recruitingFirm',
          key: '0002',
          title: '会员招募'
        },
        {
          children: [],
          href: '/information',
          key: '0003',
          title: '协会动态'
        }, {
          children: [],
          href: '/solution',
          key: '0004',
          title: '关于我们'
        }, {
          children: [],
          href: '/business',
          key: '0005',
          title: '有奖征集'
        }
      ],
      logo: 'https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/92b8afc16b3f40bc85769aabce1e9d5e.png',
      logo1: 'https://bcmarket.obs.cn-south-1.myhuaweicloud.com/files/3de558ade8484d82b7d46df1f667919b.png',
      meta_setting: {
        title: '',
        keywords: '',
        description: ''
      },
      visible: false,
      defaultSelected: [], // 默认选择,
      openKeys: []
    }
  },
  async created () {
    this.$set(this, 'defaultSelected', [getMenuPath(this.$route.path)])
    this.getbannerList()
  },
  mounted () {
    const header = document.getElementById('header')
    this.$store.commit('getHeaderHeight', header.offsetHeight)
  },
  methods: {
    // 回到顶部
    goTop () {
      backTop()
    },
    // 判断-> 当前项是否被选中
    checkRouter (item) {
      const path = getMenuPath(this.$route.path)// 页面当前路由
      let flag = false
      if (!path && item.href === '/home') {
        flag = true
      }
      if (path === item.href) {
        flag = true
      }
      if (!flag) {
        item.children.forEach((item) => {
          if (path === item.href) {
            flag = true
          }
        })
      }
      return flag
    },
    // 打开抽屉
    async showDrawer () {
      this.visible = true
    },
    // 关闭抽屉
    onClose () {
      this.visible = false
    },
    toMenuPage (subItem) {
      this.$router.push({ path: subItem.href })
    },
    goHome () {
      this.$router.push({
        name: 'Home'
      })
    },
    getbannerList () {
      const obj = {
        page: 1,
        per_page: 200
      }
      // getBannerList(obj).then(res => {
      //   console.log('公司logo', res)
      //   for (let i = 0; i < res.length; i++) {
      //     if (res[i].title === '公司logo') {
      //       this.logo = res[i].list[0].title_img
      //       break
      //     }
      //   }
      // }).catch((err) => {
      //   console.log(err)
      // })
    }
  }
}
</script>

<style lang="scss" scoped>

.title-text{
  flex:1;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow:ellipsis;
}
.fz-24 {
  font-size: 24px;
}
::v-deep .ant-drawer-title {
  height: 50px;
  div,
  a {
    height: calc(100% - 2px);
    display: inline-block;
    padding: 5px;
  }
  img {
    width: 36px;
    height: 36px;
    max-width: 36px;
  }
}
::v-deep .ant-drawer-header {
  padding: 0;
}
::v-deep .ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border: none;
}

::v-deep .ant-menu-item-selected::after {
  display: none !important;
}

::v-deep .ant-menu-item-selected {
  background: #f3f4f5 !important;
  color: #00a0ac;
}

::v-deep .ant-menu-submenu-title:hover,
::v-deep .ant-menu-submenu-active,
::v-deep .ant-menu-submenu-selected {
  color: #00a0ac;
}

::v-deep .ant-menu-submenu-arrow::after,
::v-deep .ant-menu-submenu-arrow::before {
  background: #00a0ac !important;
}

.header {
  background: #fff;
  box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.1);
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 10;
  box-sizing: border-box;
  padding: 0 50px;

  .navigation {
    margin: 0 auto;
    height: 100%;
    margin: 0 auto;
    padding: 0 6px;
    padding-right: 12px;
    .nav-left {
      height: 100%;
      .left-logo{
        display: flex;
        font-size: 16px;
        font-family: PingFang SC, PingFang SC-400;
        font-weight: 400;
        text-align: CENTER;
        color: #000000;
        line-height: 65px;
        cursor: pointer;
        img {
          width:50px;
          // height: 100%;
        }
      }

    }
    .nav-right {
      height: 100%;
      .icon-name{
        display: none;
      }
      .nav-list {
        display: flex;
        height: 100%;
        > li {
          font-size: 16px;
          position: relative;
          // display: inline-block;
          height: 68px;
          &.current {
            // background: #00a0ac;
            // color: #fff !important;
            border-bottom: 3px solid #00a0ac;
          }
          > a {
            width: 120px;
            height: 36px;
            // border-radius: 4px;
            display: block;
            line-height: 36px;
            text-align: center;
            color: #000000;

            &:hover {
              color: #00a0ac;
            }
          }
          .dropdown {
            z-index: 9;
            position: absolute;
            top: 68px;
            left: -10px;
            display: none;
            li {
              height: 50px;
              a {
                display: block;
                min-width: 140px;
                height: 100%;
                padding: 0 15px;
                text-align: center;
                line-height: 50px;
                color: #000000;
                background: white;
                white-space: nowrap;
                &:hover {
                  background: #eeeeee;
                }
              }
            }
          }
        }
      }
    }
  }
}
.nav-item:hover .dropdown {
  display: block!important;;
}
</style>

<style lang="scss" scoped>
@import './mediaStyle/header';   // 移动端适配样式
</style>
